import cx from 'classnames';
import React, { useContext, useRef } from 'react';
import { withRouter } from 'react-router-dom';
// Router
import router from './router';
// Hooks
import useDeviceDetection from './hooks/useDeviceDetection';
import useLockBodyScroll from './hooks/useLockBodyScroll';
// Contexts
import { ModalContext } from './context/modal';
import { SandboxContext } from './context/sandbox';
// Components
import Main from 'components/Main';
import Modal from './components/Modal';
import Header from './components/Header';
import Footer from './components/Footer';

import styles from './App.module.css';

function App({ history }) {
  const { drawing, toggleDrawing } = useContext(SandboxContext);
  const { modal, setModalParams } = useContext(ModalContext);

  const modalRef = useRef(null);

  const { isMobile } = useDeviceDetection();

  // Lock WEB scroll when Sandbox is active
  useLockBodyScroll(drawing);

  const classNames = cx({
    [styles.app]: true,
  });

  const isHomePage = history.location.pathname === '/';

  const pushHomeStartDrawing = () => {
    history.push('/');
    toggleDrawing();
  };

  return (
    <>
      <div className={classNames}>
        <Header
          hidden={drawing}
          secondary={!isHomePage}
          className={styles.header}
          hidePlayNow={isHomePage || isMobile}
          onLogoClick={() => setModalParams(false)}
          onPlayNowClick={isHomePage ? toggleDrawing : pushHomeStartDrawing}
        />

        <Main hidden={drawing} secondary={!isHomePage}>
          {router()}
        </Main>

        <Footer secondary={!isHomePage} />
      </div>

      <Modal
        ref={modalRef}
        {...modal}
        onDismiss={() => {
          setModalParams({ opened: false });
          modal.onDismiss && modal.onDismiss();
        }}
      />
    </>
  );
}

export default withRouter(App);
